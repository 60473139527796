import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseMutationState } from 'urql';
import { Button, InputText } from '@cyber-cats/uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { CustomInput } from 'ui/forms/custom/types';
import { CvvModal } from 'ui/components/CvvModal';
import { validateCreditCard } from 'utils/creditCardValidator';

import { buildName } from '../helpers';

const MAX_CVV_LENGTH = 4;

const CustomFormCvv = ({
  field,
  required = false,
  prefix,
  formResult,
}: {
  field: CustomInput;
  required?: boolean;
  prefix?: string;
  formResult: UseMutationState | undefined;
}) => {
  const [showCVVModal, setShowCVVModal] = useState(false);
  const t = useTranslate();
  const { watch, register, setValue } = useFormContext();
  const { resolveInputError, getErrorMessageOrDefault } = useFormField(field);

  const cardNumber = watch('cardNumber');

  const cardNumberResult = validateCreditCard(cardNumber ?? '');

  useEffect(() => {
    if (formResult?.error) {
      setValue(buildName('cvv', prefix), '');
    }
  }, [formResult?.error, prefix, setValue]);

  return (
    <>
      <InputText
        id={field.id}
        label={field.label}
        key={field.name}
        dataTestId={field.dataTestId}
        name={field.name}
        placeholder={field.placeholder || ''}
        errorText={resolveInputError()}
        required={required}
        autoComplete="cc-csc"
        autoCorrect="off"
        spellCheck={false}
        inputMode="numeric"
        type="tel"
        ref={register({
          required:
            required && getErrorMessageOrDefault(field.name, 'requiredField'),
          pattern: {
            message: getErrorMessageOrDefault(field.name, 'validValue'),
            value: new RegExp('^[0-9]{' + cardNumberResult.minCVV + '}$'),
          },
          minLength: {
            value: cardNumberResult.minCVV,
            message: getErrorMessageOrDefault(
              field.name,
              'genericFieldMinLength',
              { length: cardNumberResult.minCVV }
            ),
          },
          maxLength: {
            value: MAX_CVV_LENGTH,
            message: t<'genericFieldMaxLength'>('genericFieldMaxLength', {
              length: MAX_CVV_LENGTH,
            }),
          },
        })}
        minLength={cardNumberResult.minCVV}
        maxLength={MAX_CVV_LENGTH}
        inputSlot={({ invert, disabled }) => (
          <Button
            label={t('whereToFindCVV')}
            icon="info"
            mode="icon"
            variant="ghost"
            onClick={() => setShowCVVModal(!showCVVModal)}
            role="switch"
            aria-pressed={showCVVModal}
            invert={invert}
            dimmed={disabled}
          />
        )}
      />
      {showCVVModal && <CvvModal handleClose={() => setShowCVVModal(false)} />}
    </>
  );
};
export default memo(CustomFormCvv);
