import { Button } from '@cyber-cats/uds/elements';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { UseMutationState } from 'urql';

import { CustomButton } from 'ui/forms/custom/types';
import { useTranslate } from 'hooks/useTranslations';

const CustomFormButton = ({
  button,
  result,
}: {
  button: CustomButton;
  result: UseMutationState | undefined;
}) => {
  const { formState } = useFormContext();
  const t = useTranslate();

  const type = button.type ?? 'submit';

  const isLoading = type === 'submit' && result?.fetching;
  const isDisabled = !formState.isValid;

  return (
    <Button
      className={button.className}
      dataTestId={button.dataTestId}
      id={button.id}
      key={button.key}
      label={button.label}
      loading={isLoading}
      dimmed={isLoading || isDisabled}
      dimmedReason={isDisabled ? t('pleaseCorrectInputErrors') : ''}
      disabled={isDisabled}
      type={type}
      size={button.size ?? 'lg'}
      variant={button.variant}
    />
  );
};

export default React.memo(CustomFormButton);
