import { useState } from 'react';
import { tw, css } from 'twind/css';
import { ButtonUnderline } from '@cyber-cats/uds/elements';

import { ProductMeasurements, SizeGroup } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { useFeature } from 'hooks/useFeature';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { Skeleton } from 'ui/components/Skeleton';
import { getSizesFromSizeGroups } from 'utils/products';
import { FITANALYTICS_FIT_FINDER_PLACEMENT } from 'utils/constants';
import { AnalyticsEvents, event } from 'utils/analytics';
import { usePageEventsContext } from 'hooks/usePageEventsContext';

import { StockMessageSFCC } from './StockMessageSFCC';
import { SizeGuide } from './SizeGuide';
import { TileSizes } from './shared';
import { SizeOptions } from './SizeOptions';
import { SizeGroups } from './SizeGroups';

type SizesProps = {
  addToCartReturnCode?: string;
  categoryId?: string | null;
  desiredSize?: string;
  inventory?: number;
  modelMeasurementText?: string | null;
  onChange?: (selectedSize: string) => void;
  productMeasurements?: ProductMeasurements | null;
  sizeGroups: SizeGroup[];
  stale?: boolean;
  tileSize?: TileSizes;
  isModalSizeOpen?: boolean;
  sizeChartId?: string | null;
  productInformation?: { id: string; name: string };
  productDivision?: string | null;
};

const columnSizes = {
  [TileSizes.SMALL]: '3rem',
  [TileSizes.MEDIUM]: '3.6rem',
  [TileSizes.LARGE]: '4.6rem',
};

export const Sizes = ({
  addToCartReturnCode,
  categoryId,
  desiredSize,
  inventory,
  onChange,
  productMeasurements,
  sizeGroups,
  stale,
  isModalSizeOpen = false,
  sizeChartId,
  tileSize = TileSizes.MEDIUM,
  productInformation,
  productDivision,
}: SizesProps) => {
  const t = useTranslate();
  const [showSizeGuide, setShowSizeGuide] = useState(false);

  const {
    staticFeatures: { useStockInfoRedesign },
  } = useSiteConfig();

  const showOnlyOrderableSizes = useFeature('SHOW_ONLY_ORDERABLE_SIZES');
  const showStockMessageBySFCCLogic = useFeature(
    'PDP_SHOW_DETAILED_STOCK_MESSAGES'
  );
  const enableStockMessage = useFeature('PDP_ENABLE_STOCK_MESSAGE');
  const sizes = getSizesFromSizeGroups(sizeGroups);

  const selectedSize = desiredSize
    ? sizes?.find(x => x.id === desiredSize)
    : undefined;

  const sizePickerStyle = {
    gridTemplateColumns: `repeat(auto-fill, minmax(${columnSizes[tileSize]}, 1fr))`,
  };
  const { pageviewEventHasFired } = usePageEventsContext();

  const triggerEvent = () => {
    event(AnalyticsEvents.GA4_CustomEvent, {
      event_name: AnalyticsEvents.GA4EC_Sizechart,
      event_params: {
        item_id_ep: productInformation?.id,
        item_name_ep: productInformation?.name,
      },
    });
  };

  // Show loading skeleton if we don't have sizes yet
  if (!sizes?.length && stale) {
    return (
      <div className="flex flex-col gap-6">
        <span className="sr-only">Loading product sizes</span>
        <div className="grid gap-1" style={sizePickerStyle}>
          {[...Array(15)].map((_, i) => (
            <Skeleton
              key={i}
              className={tw('w-full aspect-1-1')}
              delay={`${(i + 1) * 100}ms`}
            />
          ))}
        </div>
      </div>
    );
  }

  if (!sizes) return null;

  // Only show size group options if we have some size groups with labels.
  // In cases where there are no size grouping, we still get a nominal size
  // group with no label, and we don't want to display a button in that case
  const displaySizeGroupOptions = sizeGroups.filter(x => !!x.label).length > 0;

  return (
    <div className="space-y-4 relative">
      {displaySizeGroupOptions ? (
        <SizeGroups
          sizeGroups={sizeGroups}
          layoutStyle={sizePickerStyle}
          showOnlyOrderableSizes={showOnlyOrderableSizes}
          desiredSize={desiredSize}
          onChange={onChange}
        />
      ) : (
        <SizeOptions
          sizes={sizes}
          layoutStyle={sizePickerStyle}
          showOnlyOrderableSizes={showOnlyOrderableSizes}
          desiredSize={desiredSize}
          onChange={onChange}
        />
      )}

      {categoryId && !isModalSizeOpen && (
        <>
          <div
            className={tw([
              'flex',
              css({
                '>.fitanalytics__button.primary': {
                  marginLeft: 'unset !important',
                  marginRight: '0.75 rem',
                },
              }),
            ])}
          >
            <ButtonUnderline
              {...FITANALYTICS_FIT_FINDER_PLACEMENT}
              label={t('sizeGuide')}
              icon="ruler"
              size="sm"
              transform="uppercase"
              iconEnd
              onClick={() => {
                pageviewEventHasFired && triggerEvent();
                setShowSizeGuide(true);
              }}
              className="min-h-6"
            />
          </div>
          {showSizeGuide && (
            <SizeGuide
              sizeChartId={sizeChartId}
              categoryId={categoryId}
              productMeasurements={productMeasurements}
              onClickOutside={() => setShowSizeGuide(false)}
              productDivision={productDivision}
            />
          )}
        </>
      )}

      {!useStockInfoRedesign &&
        showStockMessageBySFCCLogic &&
        enableStockMessage && (
          <StockMessageSFCC
            selectedSize={selectedSize}
            quantity={inventory}
            addToCartReturnCode={addToCartReturnCode}
          />
        )}
    </div>
  );
};
