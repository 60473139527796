import { Text } from '@cyber-cats/uds/elements';

import { ProductPrice } from 'ui/components/ProductPrice/ProductPrice';

import { SearchProductResult } from './types';

type SearchProductListItemProps = {
  product: SearchProductResult;
};

export const SearchProductListItem = ({
  product,
}: SearchProductListItemProps) => {
  return (
    <div className="flex gap-4 items-center group">
      <img
        src={product.image?.href}
        alt={product.image?.alt}
        className="flex-none w-[100px] h-[100px]"
      />
      <div className="flex flex-col gap-2">
        <Text
          dataTestId="search-product-name"
          weight="bold"
          className="block group-hover:text-underline"
        >
          {product.name}
        </Text>
        <div className="flex gap-2 flex-wrap">
          <ProductPrice
            price={{
              amount: product.price,
              salePrice: product.salePrice,
              isSalePriceElapsed: product.isSalePriceElapsed,
            }}
            compact={true}
          />
        </div>
      </div>
    </div>
  );
};
