import React from 'react';
import { Text } from '@cyber-cats/uds/elements';

import { useTranslate } from 'hooks/useTranslations';

const CustomFormText = ({ field }: { field: any }) => {
  const t = useTranslate();
  return (
    <Text size="xs" key={field.name}>
      {field.key ? t(field.key) : ''}
    </Text>
  );
};

export default React.memo(CustomFormText);
