import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  InputText,
  Stack,
  LiveRegion,
  Alert,
  Button,
} from '@cyber-cats/uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormData } from 'hooks/useFormData';
import { useResetPasswordMutation } from '__generated__/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';

type ForgotPasswordFormProps = {
  isInPopover?: boolean;
};

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { isInPopover } = props;
  const t = useTranslate();
  const [validate] = useValidation();
  const { data } = useFormData();
  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
    watch,
  } = useForm<{
    email: string;
  }>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { email: data?.email },
  });

  const { showModifiedResetPassFormPopup } = useSiteConfig();

  const [afterForgotPassPopup, setAfterForgotPassPopup] = useState(false);

  const [resetPasswordResult, resetPassword] = useResetPasswordMutation();

  const onSubmit = async data => {
    if (!formState.isValid) return;
    await resetPassword(data);
    setAfterForgotPassPopup(true);
  };

  const confirmPopupOpenJapan =
    afterForgotPassPopup && showModifiedResetPassFormPopup;

  const confirmPopupOpenOthers =
    afterForgotPassPopup && !showModifiedResetPassFormPopup;

  const email = watch('email');

  return (
    <form
      className="space-y-8 pt-2 pb-16 sm:pb-0"
      data-test-id="forgotten-password-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className={`${isInPopover && 'text-center'} space-y-2`}>
        <h1
          data-test-id="forgotten-password-title"
          className={`${
            isInPopover ? 'text-lg' : 'text-xl text-center'
          }  font-bold uppercase`}
        >
          {afterForgotPassPopup
            ? t('resetPasswordConfirm')
            : t('forgotPassword')}
        </h1>

        {confirmPopupOpenJapan && (
          <>
            <p
              role="alert"
              className="text-puma-green"
              data-test-id="password-reset-email-sent-success"
            >
              {t('emailSentMessage')}
              <br />
              {email}
            </p>
            <p>{t('checkEmailSuggestionsMessage')}</p>
            <p>{t('checkIfNoEmailMessage')}</p>
            <ul className="list-decimal pl-6">
              <li>{t('checkYourEmailAfterMessage')}</li>
              <li>{t('allowItToBeReceivedMessage')}</li>
              <li>{t('reSendEmailMessage')}</li>
            </ul>
          </>
        )}
        {confirmPopupOpenOthers && (
          <>
            <p
              role="alert"
              className="text-puma-green"
              data-test-id="password-reset-email-sent-success"
            >
              {t('emailSentMessage')} <br /> {email}.
            </p>
            <p>{t('checkEmailSuggestionsMessage')}</p>
            <ul
              className="list-disc pl-6"
              data-test-id="email-reset-instructions-support-text"
            >
              <li>{t('waitAfewMinutesMessage')}</li>
              <li>{t('checkSpamFolderMessage')}</li>
              <li>{t('reSendEmailMessage')}</li>
              <li>{t('sendMailToSupportMessage')}</li>
              <li>{t('provideCorrectEmailMessage')}</li>
            </ul>
          </>
        )}
        <p className="text-base">
          {afterForgotPassPopup
            ? t('provideContactOfficialMessage')
            : t('provideAccountEmailMessage')}
        </p>
      </div>

      <LiveRegion type="assertive">
        {resetPasswordResult.error && (
          <Alert
            variant="error"
            dataTestId="forgotten-password-error"
            content={
              resetPasswordResult.error &&
              (resetPasswordResult.error?.graphQLErrors[0]
                ? resetPasswordResult.error?.graphQLErrors[0].message ||
                  t('somethingWentWrong')
                : t('networkError'))
            }
          />
        )}
      </LiveRegion>

      <Stack gap="lg">
        <InputText
          ref={registerField({
            required: t('requiredField'),
            validate: validate.email,
            maxLength: {
              value: 50,
              message: t<'yourEmailCannotBeLong'>('yourEmailCannotBeLong', {
                length: 50,
              }),
            },
          })}
          dataTestId="email-input"
          id="email-input"
          label={t('email')}
          name="email"
          type="email"
          maxLength={50}
          errorText={errors.email?.message}
          placeholder={t('email')}
          required
        />
        <Button
          size="lg"
          dataTestId="submit-password-reset-btn"
          label={t('submit')}
          loading={resetPasswordResult.fetching}
          disabled={resetPasswordResult.fetching}
          type="submit"
          bleed
        />
      </Stack>
    </form>
  );
};
