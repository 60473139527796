import { IconProps } from '@cyber-cats/uds/elements';

import { useFeature } from 'hooks/useFeature';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { PAYMENT_PARTNERS_UNION } from 'sites/types';
import { CardFrameIcon } from 'ui/forms/PaymentMethods/CardFrameIcon';

const PARTNER_NAME_TO_LOGO: Record<PAYMENT_PARTNERS_UNION, IconProps['name']> =
  {
    afterPay: 'payment-afterpay',
    amazonPay: 'payment-amazon-pay',
    americanExpress: 'payment-amex',
    applePay: 'payment-apple-pay',
    diners: 'payment-diners-club',
    discover: 'payment-discover',
    googlePay: 'payment-google-pay',
    JCB: 'payment-jcb',
    klarna: 'payment-klarna',
    maestro: 'payment-maestro',
    master: 'payment-mastercard',
    payPal: 'payment-paypal',
    payPay: 'payment-paypay',
    visa: 'payment-visa',
    zip: 'payment-zip',
    weChatPay: 'payment-wechat-pay',
    alipay: 'payment-alipay',
    UPI: 'payment-upi',
    rupay: 'payment-rupay',
  };

export const AcceptedPaymentMethods = ({
  className,
  dataTestId,
  ariaLabel,
  filterFn,
}: {
  className: string;
  ariaLabel?: string;
  dataTestId?: string;
  filterFn?: (paymentPartner: PAYMENT_PARTNERS_UNION) => boolean;
}) => {
  const {
    orderedPaymentPartners,
    staticFeatures: { isPNARegion },
  } = useSiteConfig();
  const isKlarnaEnabled = useFeature('KLARNA_ENABLED');

  let filteredPaymentPartners = orderedPaymentPartners;
  if (filterFn) {
    filteredPaymentPartners = orderedPaymentPartners.filter(filterFn);
  }
  if (
    isPNARegion &&
    !isKlarnaEnabled &&
    filteredPaymentPartners.includes('klarna')
  )
    filteredPaymentPartners.splice(
      filteredPaymentPartners.indexOf('klarna'),
      1
    );
  return (
    <ul className={className} data-test-id={dataTestId} aria-label={ariaLabel}>
      {filteredPaymentPartners?.map(paymentPartner => (
        <li key={paymentPartner} className="flex bg-white rounded-sm">
          <CardFrameIcon
            name={PARTNER_NAME_TO_LOGO[paymentPartner]}
            label={paymentPartner}
          />
        </li>
      ))}
    </ul>
  );
};
