import { Stack } from '@cyber-cats/uds/elements';
import { UdsResponsiveProp } from '@cyber-cats/uds/hooks';
import React from 'react';
import { FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form';

interface CustomFormWrapperProps {
  isNested?: boolean;
  dataTestId?: string;
  onSubmit?: (...props: any) => Promise<void>;
  form: UseFormMethods<any>;
  gapY?: UdsResponsiveProp<
    | 'min'
    | 'max'
    | 'base'
    | 'none'
    | 'auto'
    | 'xs'
    | 'sm'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | 'px'
    | 'xxs'
    | '5xl'
    | undefined
  >;
}

export const CustomFormWrapper: React.FC<CustomFormWrapperProps> = props => {
  const { isNested, children, onSubmit, form, dataTestId, gapY } = props;

  return (
    <>
      {isNested ? (
        <Stack dataTestId={dataTestId}>{children}</Stack>
      ) : (
        <FormProvider {...form}>
          <Stack asChild dataTestId={dataTestId} gapY={gapY ?? 'base'}>
            <form
              noValidate
              onSubmit={form.handleSubmit(onSubmit as SubmitHandler<any>)}
            >
              {children}
            </form>
          </Stack>
        </FormProvider>
      )}
    </>
  );
};
