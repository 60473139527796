import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { UseQueryState } from 'urql';

import {
  Variant,
  usePixleeConfigQuery,
  useTrackProductViewMutation,
  useProductScoreQuery,
  Score,
  useUserGeneratedContentQuery,
  QueryContentArgs,
  usePdpQuery,
} from '__generated__/graphql';
import type { PixleeImgType } from 'ui/pages/ProductDetailsPages/types';
import { REFERRER_CATEGORY, isServer } from 'utils/constants';
import { ProductTypeFromPDPQuery, ProductQuery } from 'types/product';
import {
  ProductDetailsPageContent,
  ProductDetailsPageQuery,
} from 'groq/pages/ProductDetailsPage';
import {
  ProductDetailsPageTemplateQuery,
  ProductDetailsPageTemplateContent,
} from 'groq/pages/ProductDetailsPageTemplate';
import {
  getGa4Item,
  removeGa4ItemFromMap,
  setGa4ItemInMap,
} from 'utils/ga4Items';

import { useFeature } from './useFeature';
import { ContentResponse, useGroqQuery } from './useGroqQuery';
import { useEmarsysWebExtend } from './useEmarsysWebExtend';

const isGoogleShoppingLink = (url: string): boolean => url.includes('gclid');

type PageLayout =
  | 'full'
  | 'simplified'
  | 'soldoutWithRecommender'
  | 'noVariant';

type ProductDivision = 'Footwear' | 'Other';

type PdpContextType = {
  addToCartError: string | undefined;
  addToCartReturnCode: string | undefined;
  desiredProductId: string;
  desiredModel: string;
  desiredSize: string | undefined;
  desiredSwatch: string | undefined;
  hasLoaded: boolean;
  isAppOnly: boolean;
  isFetching: boolean;
  isFullLayout: boolean;
  isQueryStale: boolean;
  isSimplifiedLayout: boolean;
  masterProduct: ProductTypeFromPDPQuery;
  pageLayout: PageLayout;
  pixleeImgs?: PixleeImgType[];
  productIsOrderable: boolean;
  productDivision: ProductDivision;
  productQuery: ProductQuery;
  quantity: number;
  referrerCategoryId?: string;
  setAddToCartError: (s: string | undefined) => void;
  setAddToCartReturnCode: (s: string | undefined) => void;
  setQuantity: (n: number) => void;
  variation: Variant | undefined | null;
  content:
    | (ProductDetailsPageContent & ProductDetailsPageTemplateContent)
    | undefined
    | null;
  productScore: Score | undefined;
  sizeHasChanged: boolean;
  setSizeHasChanged: (boolean) => void;
  pdpContentQuery: UseQueryState<
    ContentResponse<ProductDetailsPageContent>,
    QueryContentArgs
  >;
  pdpContentTemplateQuery: UseQueryState<
    ContentResponse<ProductDetailsPageTemplateContent>,
    QueryContentArgs
  >;
};

const PdpContext = createContext<PdpContextType>(null as any);

export const PdpProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const hasSentAnalytics = useRef(false);
  const [, trackProductView] = useTrackProductViewMutation();
  const reviewsEnabled = useFeature('RATINGS_AND_REVIEWS');

  const [addToCartError, setAddToCartError] = useState<string>();
  const [addToCartReturnCode, setAddToCartReturnCode] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1);

  const [sizeHasChanged, setSizeHasChanged] = useState(false);
  const desiredProductId = router.query.id as string;
  const desiredModel = router.query.model as string;

  const [pixleeConfigResponse] = usePixleeConfigQuery();
  const pixleeConfig = pixleeConfigResponse.data?.pixleeConfig;

  const { trackPdp } = useEmarsysWebExtend();

  const [productQuery, refetch] = usePdpQuery({
    variables: {
      id: desiredProductId,
    },
    pause: !desiredProductId,
    requestPolicy: 'cache-first',
  });

  useEffect(() => {
    refetch({ requestPolicy: 'cache-and-network' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const masterProduct = productQuery.data?.product;
  const isFetching = productQuery.fetching;
  const isQueryStale = productQuery.stale;
  const desiredSwatch =
    (router.query.swatch as string | undefined) ||
    masterProduct?.variations[0].colorValue;

  const hasDesiredProductIdAndSwatch = Boolean(
    desiredProductId && desiredSwatch
  );

  // Updates the variation when desiredSwatch changes
  const variation = useMemo(() => {
    if (isFetching && !isQueryStale) return undefined;
    const variations = (masterProduct?.variations || []) as Variant[];
    if (!masterProduct || variations.length === 0) {
      if (hasDesiredProductIdAndSwatch)
        removeGa4ItemFromMap(`${desiredProductId}_${desiredSwatch}`);
      return undefined;
    }

    // return the requested swatch, if available
    if (desiredSwatch) {
      const variation = variations.find(x => x.colorValue === desiredSwatch);
      if (variation) return variation;
    }

    // if no swatch was requested (url params) or found, try to return the first orderable swatch
    // if no orderable swatches return the first swatch
    const foundVariation = variations.find(v => v.orderable) || variations[0];
    if (foundVariation) {
      const oldGa4Item =
        hasDesiredProductIdAndSwatch &&
        getGa4Item(`${desiredProductId}_${desiredSwatch}`);
      if (oldGa4Item)
        setGa4ItemInMap(`${desiredProductId}_${foundVariation.colorValue}`, {
          lid: oldGa4Item?.lid,
          lname: oldGa4Item?.lname,
          idx: oldGa4Item?.idx,
          cname: oldGa4Item?.cname,
          cslot: oldGa4Item?.cslot,
          pid: oldGa4Item?.pid,
          pname: oldGa4Item?.pname,
        });
    }
    if (hasDesiredProductIdAndSwatch)
      removeGa4ItemFromMap(`${desiredProductId}_${desiredSwatch}`);
    return foundVariation;
  }, [
    isQueryStale,
    isFetching,
    desiredProductId,
    desiredSwatch,
    masterProduct,
    hasDesiredProductIdAndSwatch,
  ]);

  const desiredSize = router.query.size as string | undefined;

  // Feature flag to allow simplified view for urls with Google Shopping indicator
  // in them ('&gclid=someValue')
  const enableGoogleShopping = useFeature(
    'SHOW_SIMPLIFIED_PDP_FOR_GOOGLE_SHOPPING'
  );

  const pageLayout: PageLayout = useMemo(() => {
    if (enableGoogleShopping && isGoogleShoppingLink(router.asPath)) {
      return 'simplified';
    }

    if (masterProduct?.displayOutOfStock?.soldoutWithRecommender) {
      return 'soldoutWithRecommender';
    }

    if (!variation) return 'noVariant';

    return 'full';
  }, [variation, masterProduct, enableGoogleShopping, router.asPath]);

  const referrerCategoryId = useRef<string>();

  if (!isServer) {
    referrerCategoryId.current =
      window.localStorage.getItem(REFERRER_CATEGORY) ?? undefined;
  }

  const [pdpContentTemplateQuery] = useGroqQuery({
    operationName: 'ProductDetailsPageTemplate',
    query: ProductDetailsPageTemplateQuery,
  });

  const [pdpContentQuery] = useGroqQuery({
    operationName: 'ProductDetailsPage',
    query: ProductDetailsPageQuery,
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });

  const content = useMemo(() => {
    if (pdpContentQuery.fetching || pdpContentTemplateQuery.fetching)
      return undefined;

    const template = pdpContentTemplateQuery.data?.content || {};
    const content = pdpContentQuery.data?.content || {};

    return {
      ...template,
      ...content,
    } as ProductDetailsPageTemplateContent & ProductDetailsPageContent;
  }, [pdpContentQuery, pdpContentTemplateQuery]);

  const [productScoreQuery] = useProductScoreQuery({
    variables: {
      id: desiredProductId,
      includeReviews: true,
    },
    pause: !desiredProductId || !reviewsEnabled || pageLayout !== 'full',
  });

  useEffect(() => {
    // After query has successfully retrieved product data, fire view product
    // tracking event for the newly fetched product.
    if (
      !productQuery.fetching &&
      productQuery.data?.product &&
      !hasSentAnalytics.current
    ) {
      const product = productQuery.data.product;
      trackProductView({ masterId: product.id });
      trackPdp(`${desiredProductId}_${desiredSwatch}`);
      hasSentAnalytics.current = true;
    }
  }, [productQuery.fetching, desiredProductId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    hasSentAnalytics.current = false;
  }, [desiredProductId]);

  // Gets pixlee user generated content associated with the product for display.
  const [ugcResponse] = useUserGeneratedContentQuery({
    variables: {
      productId: desiredProductId,
    },
    pause: !desiredProductId,
  });
  const userGeneratedContent = ugcResponse.data?.userGeneratedContentForWeb;

  const pixleeImgs: PixleeImgType[] = useMemo(() => {
    if (!pixleeConfig?.enablePDPGallery || !userGeneratedContent) return [];

    return userGeneratedContent
      .filter(content => content && content.pixleeCdnPhotos?.squareMediumUrl)
      .map(content => ({
        __typename: 'PixleeImg',
        source: content!.source,
        alt: content!.altText || '',
        href: content!.pixleeCdnPhotos!.squareMediumUrl!,
      }));
  }, [pixleeConfig, userGeneratedContent]);

  // Product is orderable if it has a price and is not soldout, comingsoon or
  // outOfStock
  const productIsOrderable = useMemo(() => {
    const displayOutOfStock = variation?.displayOutOfStock;
    const { comingsoon, soldout, soldoutWithRecommender } =
      displayOutOfStock ?? {};
    // 'soldout' is set manually, 'outOfStock' is based on stock levels
    const outOfStock = !variation?.orderable;
    return Boolean(
      variation?.price &&
        !(soldout || soldoutWithRecommender || comingsoon || outOfStock)
    );
  }, [variation?.displayOutOfStock, variation?.price, variation?.orderable]);

  const isAppOnly = !!variation?.isAppExclusive;

  const productDivision: ProductDivision = useMemo(() => {
    return masterProduct?.productDivision === 'Footwear' ? 'Footwear' : 'Other';
  }, [masterProduct?.productDivision]);

  const providerValue = useMemo(
    () => ({
      addToCartError,
      addToCartReturnCode: addToCartReturnCode,
      desiredProductId,
      desiredModel,
      desiredSize,
      desiredSwatch,
      hasLoaded: !productQuery.fetching && !productQuery.stale,
      isAppOnly,
      isFetching,
      isFullLayout: pageLayout === 'full',
      isQueryStale,
      isSimplifiedLayout: pageLayout !== 'full',
      masterProduct,
      pageLayout,
      pixleeImgs,
      productIsOrderable,
      productDivision,
      productQuery,
      quantity,
      referrerCategoryId: referrerCategoryId.current,
      setAddToCartError,
      setAddToCartReturnCode: setAddToCartReturnCode,
      setQuantity,
      variation,
      content,
      productScore: productScoreQuery.data?.productScore as Score,
      sizeHasChanged,
      setSizeHasChanged,
      pdpContentQuery,
      pdpContentTemplateQuery,
    }),
    [
      addToCartError,
      addToCartReturnCode,
      desiredProductId,
      desiredModel,
      desiredSize,
      desiredSwatch,
      isAppOnly,
      isFetching,
      isQueryStale,
      masterProduct,
      pageLayout,
      pixleeImgs,
      productIsOrderable,
      productDivision,
      productQuery,
      quantity,
      referrerCategoryId,
      setAddToCartError,
      setAddToCartReturnCode,
      setQuantity,
      variation,
      content,
      productScoreQuery,
      sizeHasChanged,
      setSizeHasChanged,
      pdpContentQuery,
      pdpContentTemplateQuery,
    ]
  );

  return (
    <PdpContext.Provider value={providerValue}>{children}</PdpContext.Provider>
  );
};

// For Product Details Pages
export const usePdp = () => useContext(PdpContext);
