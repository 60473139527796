import { Icon, IconProps } from '@cyber-cats/uds/elements';
import { tw } from 'twind';

type CardFrameIconProps = { borderless?: boolean } & IconProps;
export const CardFrameIcon = ({ borderless, ...props }: CardFrameIconProps) => (
  <span
    className={tw(
      'border-1 rounded-sm flex',
      borderless ? 'border-transparent' : 'border-puma-gray'
    )}
  >
    <Icon {...props} size="4xl" aspectRatio={0.6} />
  </span>
);
