import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Alert, InputText } from '@cyber-cats/uds/elements';
import { useRouter } from 'next/router';

import { GraphQlError, VerifyLoginWithOtpCode } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useFormData } from 'hooks/useFormData';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { NavigationFunction, OTPAuthStage } from 'ui/forms/OTPForm';
import TermsAndConditions from 'ui/components/TermsAndConditions';
import TermsAndConditionsBackup from 'ui/components/TermsAndConditionsBackup';
import { extractCode, extractError } from 'utils/extractError';
import { invertedTextMask } from 'utils/textMask';
import { loginEvent } from 'utils/analytics';
import { usePageEventsContext } from 'hooks/usePageEventsContext';

type ConfirmOTPForm = {
  otp: string;
};

const GraphqlOTPErrorCodes = [
  GraphQlError.OtpLoginDisabled,
  GraphQlError.WrongPin,
  GraphQlError.InternalServerError,
  GraphQlError.TtlExpired,
] as const;

// prettier-ignore
type GraphqlOTPErrorCode = (typeof GraphqlOTPErrorCodes)[number];

type ClientErrorMsgs = {
  [key in VerifyLoginWithOtpCode]: string;
};

// TODO: This is already a refactored version of the messages. Ideally we want to decouple these from the frontend and make it injectable from sanity.
const OTP_CLIENT_ERROR_MESSAGES: Omit<
  ClientErrorMsgs,
  'CUSTOMER_NOT_FOUND' | 'OK'
> = {
  MORE_THAN_ONE_CUSTOMER:
    "We couldn't log you in as your phone number is linked to more than one accounts. Please contact the customer support team.",
  ENCRYPTED_PASSWORD_EMPTY:
    'Please log in using your password in order to enable login via OTP for next time.',
  NO_MORE_PIN_ATTEMPTS: `You've exceeded the maximum number of attempts to enter correct OTP codes. Please try after some time or contact customer care`,
};

const OTP_GQL_ERROR_MESSAGES = {
  OTP_LOGIN_DISABLED:
    'Kindly login with your phone number or email and password as the OTP option is currently unavailable',
  WRONG_PIN:
    'The OTP entered is incorrect. Please enter correct OTP or try regenerating the OTP',
  INTERNAL_SERVER_ERROR: 'Something went wrong',
  TTL_EXPIRED: 'The OTP entered has expired. Please regenerate a new OTP',
};

export const ConfirmOTP = (props: {
  onSuccess?: () => void;
  navigate: NavigationFunction;
}) => {
  const t = useTranslate();

  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
  } = useForm<ConfirmOTPForm>({ mode: 'onChange' });

  const timerRef = React.useRef<NodeJS.Timer | null>(null);

  const { validation } = useSiteConfig();

  const { data, setValues } = useFormData();

  const { pageviewEventHasFired } = usePageEventsContext();

  const {
    verifyLoginWithOTP: [verifyLoginWithOTPResult, verifyLoginWithOTP],
    resendOTP: [resendOTPResult, resendOTP],
  } = useLoginAndRegister();

  const router = useRouter();

  const [timer, setTimer] = React.useState(20);

  const setUpTimer = React.useCallback(() => {
    setTimer(20);

    timerRef.current = setInterval(() => setTimer(prev => --prev), 1000);
  }, []);

  const tearDownTimer = React.useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);
  }, []);

  React.useEffect(() => {
    if (timerRef.current && timer <= 0) clearInterval(timerRef.current);
  }, [timer]);

  React.useEffect(() => {
    setUpTimer();

    return () => {
      tearDownTimer();
    };
  }, [setUpTimer, tearDownTimer]);

  const executeRedirect = (
    error: VerifyLoginWithOtpCode | GraphqlOTPErrorCode
  ) => {
    const isRegisterRedirectPath = error === 'MORE_THAN_ONE_CUSTOMER';
    if (isRegisterRedirectPath) props.navigate(OTPAuthStage.register);

    const isLoginWithEmailPath = [
      'ENCRYPTED_PASSWORD_EMPTY',
      'OTP_LOGIN_DISABLED',
    ].includes(error);
    if (isLoginWithEmailPath) props.navigate(OTPAuthStage.login_with_email);
  };

  const handleLoginRedirect = () => {
    setValues({ error: '' }); // reset error when user goes back to login page
    props.navigate(OTPAuthStage.login);
  };

  const handleResendOTP = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    tearDownTimer();

    setUpTimer();

    const result = await resendOTP({
      pinId: data.pinId,
    });

    if (result.data?.resendOTP) {
      setValues({ pinId: result.data?.resendOTP });
    }
  };

  const onSubmit = async (formDataValues: ConfirmOTPForm) => {
    try {
      setValues({ error: '' });

      const result = await verifyLoginWithOTP({
        pin: formDataValues.otp,
        pinId: data.pinId,
        phoneNo: data.phoneNo,
      });

      const errorCode = extractCode(result); // used to extract GQL errors
      const { code } = result.data?.verifyLoginWithOTP ?? {};

      // exit early if there is no customer with number
      if (code === 'CUSTOMER_NOT_FOUND') props.navigate(OTPAuthStage.register);

      if (code === 'OK' && props.onSuccess) {
        const { email, customerNo } =
          result.data?.verifyLoginWithOTP.authPayload?.user || {};

        if (pageviewEventHasFired) loginEvent(email, customerNo, router.asPath);
        props.onSuccess();
      }
      if (code) {
        setValues({ error: OTP_CLIENT_ERROR_MESSAGES[code] });
        executeRedirect(code);
      } else if (errorCode) {
        setValues({ error: OTP_GQL_ERROR_MESSAGES[errorCode] });
        executeRedirect(errorCode as GraphqlOTPErrorCode);
      }
    } catch (e: any) {
      setValues({ error: e?.message });
    }
  };

  const error = data.error ?? extractError(verifyLoginWithOTPResult);

  React.useEffect(() => {
    if (!data.phoneNo) props.navigate(OTPAuthStage.login);
  }, [data.phoneNo, props.navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const maskedPhoneNumber = invertedTextMask(
    data.phoneNo,
    validation.phone.prefix?.length ?? 0,
    3,
    '#'
  );

  return (
    <form
      className="flex flex-col space-y-4"
      data-test-id="login-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {error && (
        <Alert
          dataTestId="confirm-form-error"
          variant="error"
          content={error}
        />
      )}
      <div>
        <p>{t('enterOTP')}</p>
        <p>
          <span className="font-bold">{maskedPhoneNumber}</span>{' '}
          <Button
            variant="underline"
            onClick={handleLoginRedirect}
            label={t('change')}
          />
        </p>
      </div>
      <InputText
        label={t('otpLabel')}
        id="otp"
        dataTestId="confirm-field-phone"
        ref={registerField({
          required: t('requiredField'),
          maxLength: 4,
        })}
        name="otp"
        error={!!errors.otp?.message}
        errorText={errors.otp?.message}
        maxLength={4}
        placeholder={t('otpPlaceholder')}
        required
      />
      <TermsAndConditions
        page="tacOnCreateAccount"
        backupContent={<TermsAndConditionsBackup page="tacOnCreateAccount" />}
      >
        <Button
          dataTestId="otp-confirm-btn"
          label={t('login')}
          id="login-submit"
          loading={verifyLoginWithOTPResult.fetching}
          disabled={
            verifyLoginWithOTPResult.fetching ||
            resendOTPResult.fetching ||
            !formState.isValid
          }
          type="submit"
          dimmedReason={t('pleaseCorrectInputErrors')}
        />
      </TermsAndConditions>
      {timer > 0 ? (
        <p className="mt-4">
          {t<'resendOTPTimer'>('resendOTPTimer', { timer })}
        </p>
      ) : (
        <p className="text-puma-black">
          {t('didNotGetTheCode')}{' '}
          <Button
            dataTestId="resend-otp"
            variant="underline"
            onClick={handleResendOTP}
            label={t('resend')}
          />
        </p>
      )}
    </form>
  );
};
