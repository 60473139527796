import { createConfig } from '@cyber-cats/uds/theme';
import { css, apply } from 'twind/css';
import typography from '@twind/typography';

import {
  attentionButtonAppearAnimation,
  attentionButtonBorderAnimation,
} from 'ui/animations/button';

const twindConfig = createConfig({
  mode: 'silent',
  preflight: `

    main,
    dialog {
      @apply bg-white;
    }

    body {
      @apply text-neutral-100 bg-white;
    }

    body:not(.focus-visible) * {
      outline: none !important;
    }

    .focus-visible :focus {
      outline: none !important;
      @apply ring-gray-500 ring-2 rounded-sm;
    }

    .focus-visible input:focus + [data-type='check-btn'] {
      @apply ring-gray-500 ring-2 ring-opacity-90;
    }

    .focus-visible input:focus + [data-type='radio-btn'] {
      @apply ring-gray-500 ring-2 ring-opacity-90;
    }

    .focus-visible [data-test-id='dropdown-select'] {
      @apply focus-within:ring-2 ring-gray-500;
    }

    .focus-visible input:focus,
    .focus-visible textarea:focus {
      outline: none !important;
      @apply ring-gray-500 ring-2 rounded-sm;
    }

    .fc-confirm-order a {
      text-decoration: underline;
    }

    #ada-button-frame {
      display: none;
   }

   /* Styles for hiding scrollbar */
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .hide-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  `,
  theme: {
    extend: {
      spacing: {
        em: '1em', // w-em
        rem: '1rem', // not used
        '128': '32rem', // split hero
      },

      colors: {
        puma: {
          silver: '#999999',
          gold: '#8A7350',
          'gold-light': '#A3885F',
          green: '#008626',
          gray: { DEFAULT: '#A1A8AF', '20': '#F6F7F8' },
          'gray-light': '#BABABA',
          red: '#ba2b20',
          black: {
            DEFAULT: '#181818',
            '50': '#000000',
            '100': '#181818',
            '200': '#444444',
            '300': '#6C6C6C',
            '350': '#8c9198',
            '400': '#999999',
            '500': '#CCCCCC',
            '600': '#E5E7EB',
            '700': '#EEEEEE',
            '800': '#FAFAFA',
            '900': '#FFFFFF',
          },
        },
        swatch: {
          black: '#333',
          gray: '#9CA3AF',
          metallic: '#D1D5DB',
          brown: '#795548',
          blue: '#1976D2',
          green: '#4CAF50',
          orange: '#FB8C00',
          tan: '#D2B48C',
          beige: '#D2B48C',
          yellow: '#FFEA00',
          red: '#E53935',
          white: '#FFF',
          pink: '#F8BBD0',
          purple: '#8E24AA',
          silver: '#c0c0c0',
          gold: '#cd9a01',
          other: '#cccccc',
        },
      },
    },
  },
  plugins: {
    'nav-fade-non-selected': () => {
      return css`
        > li a {
          ${apply('opacity-50')}
        }
        > li:hover a {
          ${apply('opacity-100')}
        }
      `;
    },

    'nav-underline': () => {
      return css`
        position: relative;
        &::after {
          content: '';
          ${apply(
            'transition transform-gpu block absolute left-0 bottom-0 w-full h-0.5 bg-puma-gold',
            'opacity-0 translate-y-1'
          )}
        }
      `;
    },
    'nav-underline-selected': () => {
      return css`
        position: relative;
        &::after {
          content: '';
          ${apply('opacity-100 -translate-y-0')}
        }
      `;
    },
    'text-inherit': () => {
      return css`
        text-align: inherit;
      `;
    },
    'cms-list-styles': () => {
      return css`
        ul li {
          padding-left: 0;
          list-style-type: disc;
        }
        ol li {
          padding-left: 0;
          list-style-type: decimal;
        }
        ul,
        ol {
          margin-left: 1rem;
        }
      `;
    },
    'cms-link-styles': () => {
      return css`
        a {
          text-decoration: underline;
        }
      `;
    },
    'link-underline': () => {
      return css`
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 2px;
      `;
    },
    'signup-button-appear': () => {
      return css`
        ${attentionButtonAppearAnimation()}
      `;
    },
    'signup-button-outer': () => {
      return css`
        ${attentionButtonBorderAnimation('top', 'before')}
        ${attentionButtonBorderAnimation('bottom', 'after')}
      `;
    },
    'signup-button-inner': () => {
      return css`
        ${attentionButtonBorderAnimation('left', 'before')}
        ${attentionButtonBorderAnimation('right', 'after')}
      `;
    },
    ...typography({
      className: 'prose',
    }),
    aspect: parts => ({ 'aspect-ratio': `${parts[0]} / ${parts[1]}` }),
    'scroll-snap': parts => css`
      scroll-snap-type: ${parts[0] || 'x'} mandatory;
      > * {
        scroll-snap-align: ${parts[1] || 'start'};
      }
    `,
  },
});

export type FontSize =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | '8xl'
  | '9xl'
  | '10xl'
  | 'base';

export default twindConfig;
