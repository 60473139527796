import React from 'react';
import { Link } from '@cyber-cats/uds/elements';
import { tw } from 'twind';

import { CustomLink } from 'ui/forms/custom/types';

const CustomFormLink = ({ field }: { field: CustomLink }) => {
  return (
    <div className={tw('flex items-center justify-center', field.class)}>
      <Link
        key={field.name}
        href={field.href ?? ''}
        dataTestId={field.dataTestId}
        variant={field.variant || 'underline'}
        label={field.label}
        size={field.size ?? undefined}
        className={field.className}
      />
    </div>
  );
};

export default React.memo(CustomFormLink);
