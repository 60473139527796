import {
  Button,
  SearchContent,
  SearchListbox,
  SearchRoot,
  SearchTrigger,
} from '@cyber-cats/uds/elements';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { useProductHistory } from 'hooks/useProductHistory';
import { debounce } from 'utils/debounce';
import { getSearchViewScenario, SEARCH_DEBOUNCE_MS } from 'utils/search';
import { useSuggestionsQuery } from '__generated__/graphql';
import { useLocation } from 'hooks/useLocation';
import { trackSearchEventInBloomreach } from 'utils/bloomreach';
import {
  AnalyticsCustomEvent,
  AnalyticsEvents,
  event,
  SearchType,
} from 'utils/analytics';
import { useBodyScrollLock } from 'hooks/a11y';
import { useMainNavContext } from 'hooks/useMainNav';

import NoResultsMessage from './NoResultsMessage';
import SearchLoadingIndicator from './SearchLoadingIndicator';
import { SearchViewComponent, SearchViewScenario } from './types';
import RecommendedProducts from './Views/RecommendedProducts';
import SuggestedProducts from './Views/SuggestedProducts';
import SuggestedSearchTerms from './Views/SuggestedSearchTerms';
import TrendingSearchTerms from './Views/TrendingSearchTerms';
import RecentlyViewedProducts from './Views/RecentlyViewedProducts';

type SearchProps = {
  darkMode?: boolean;
};

export const SearchForm = ({ darkMode }: SearchProps) => {
  const t = useTranslate();
  const router = useRouter();
  const {
    localizeUrlPath,
    charactersNeededToSubmitSearch,
    searchSymbolsMinValue,
    countryCode,
  } = useSiteConfig();
  const { setIsSearchFormOpen, isSearchFormOpen } = useMainNavContext();
  const { location } = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [hasSubmittedSearch, setHasSubmittedSearch] = useState(false);

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useBodyScrollLock(isSearchFormOpen);

  const getComponentsForScenario = (
    scenario: SearchViewScenario
  ): [SearchViewComponent, SearchViewComponent] => {
    switch (scenario) {
      case SearchViewScenario.NewUser:
        return [TrendingSearchTerms, RecommendedProducts];
      case SearchViewScenario.SearchResults:
        if (searchTerm) {
          return [SuggestedSearchTerms, SuggestedProducts];
        }
        return [TrendingSearchTerms, RecommendedProducts];
      case SearchViewScenario.ReturningUser:
        if (searchTerm) {
          return [SuggestedSearchTerms, SuggestedProducts];
        }
        return [TrendingSearchTerms, RecentlyViewedProducts];
    }
  };

  const handleSearchFlyoutOpenChange = (newOpenState: boolean) => {
    if (!isSearchFormOpen && newOpenState) {
      event(AnalyticsEvents.SEARCH_MODAL_IMPRESSION);
      AnalyticsCustomEvent({
        event_name: AnalyticsEvents.SEARCH_OPEN,
        search_type: SearchType.regular,
      });
    }
    setIsSearchFormOpen(newOpenState);
  };

  const debounceSearchTerm = debounce(
    setDebouncedSearchTerm,
    SEARCH_DEBOUNCE_MS
  );

  const handleChange = (value: string) => {
    // `setSearchTerm` updates the value for the controlled search input component,
    // whereas the `debounceSearchTerm` will debounce actually invoking the search
    setSearchTerm(value);
    debounceSearchTerm(value);
  };

  const { recentlyViewedProducts } = useProductHistory();
  // a search term is considered valid if it contains the minimum number of characters
  // required for the region
  const hasValidSearchTerm =
    debouncedSearchTerm.length >= searchSymbolsMinValue;

  const searchViewScenario = useMemo(
    () => getSearchViewScenario(recentlyViewedProducts, hasValidSearchTerm),
    [recentlyViewedProducts, hasValidSearchTerm]
  );

  const [suggestionsResult] = useSuggestionsQuery({
    variables: { searchTerm: debouncedSearchTerm },
    pause: !hasValidSearchTerm,
  });

  const setSessionStorageData = useCallback(
    (
      suggestedSectionClick: string,
      suggestedElement?: string,
      redirect?: boolean
    ) => {
      window.sessionStorage.setItem('search_type', SearchType.regular);
      window.sessionStorage.setItem(
        'suggested_section_click',
        suggestedSectionClick
      );

      if (searchTerm !== '')
        window.sessionStorage.setItem('search_term', searchTerm);

      if (suggestedElement)
        window.sessionStorage.setItem(
          'suggested_element_click',
          suggestedElement
        );

      window.sessionStorage.setItem(
        'user_action',
        redirect ? 'redirect' : 'noredirect'
      );
    },
    [searchTerm]
  );

  useEffect(() => {
    if (
      hasSubmittedSearch &&
      !suggestionsResult.fetching &&
      searchTerm === debouncedSearchTerm
    ) {
      if (searchTerm && searchTerm.length >= charactersNeededToSubmitSearch) {
        trackSearchEventInBloomreach({
          query: searchTerm,
          siteCountryCode: countryCode,
          visitorCountryCode: location?.userCountry?.id,
        });

        const redirect = suggestionsResult.data?.suggestions?.suggestedRedirect;
        if (redirect) {
          setSessionStorageData('enter/search button', undefined, true);
          if (redirect.startsWith('https')) window.location.href = redirect;
          else router.replace(localizeUrlPath(redirect));
        } else {
          setSessionStorageData('enter/search button');
          router.push(
            localizeUrlPath(`/search?q=${encodeURIComponent(searchTerm)}`)
          );
        }

        setIsSearchFormOpen(false);
        setHasSubmittedSearch(false);
      }
    }
  }, [
    hasSubmittedSearch,
    suggestionsResult,
    searchTerm,
    debouncedSearchTerm,
    charactersNeededToSubmitSearch,
    countryCode,
    location?.userCountry?.id,
    router,
    localizeUrlPath,
    setSessionStorageData,
    setIsSearchFormOpen,
  ]);

  useEffect(() => {
    if (!router.pathname.includes('/[country]/[language]/search')) {
      setSearchTerm('');
    }
  }, [router.pathname]);

  const [TermListComponent, ProductListComponent] =
    getComponentsForScenario(searchViewScenario);

  // if either of the search views are loading data or we have submitted the search query
  // then show the loading indicator
  const loading = loading1 || loading2 || hasSubmittedSearch;

  const hasNoResults =
    hasValidSearchTerm &&
    !loading &&
    !suggestionsResult.data?.suggestions?.suggestedProductsSummaries?.length;

  return (
    <SearchRoot
      dataTestId="search-flyout"
      name={t('search')}
      label={t('search')}
      placeholder={t('searchPuma')?.toUpperCase()}
      open={isSearchFormOpen}
      onOpenChange={handleSearchFlyoutOpenChange}
      value={searchTerm}
      onChange={handleChange}
      onSubmit={e => {
        e.preventDefault();
        if (searchTerm && searchTerm.length >= charactersNeededToSubmitSearch) {
          setHasSubmittedSearch(true);
        }
      }}
      loading={loading}
    >
      <SearchTrigger className="hidden xl:flex">
        <Button
          data-test-id="search-button-nav"
          mode="text"
          label={t('search')}
          icon="search"
          className="mx-3"
          invert={darkMode}
          variant="secondary"
        />
      </SearchTrigger>
      <SearchTrigger className="xl:hidden">
        <Button
          data-test-id="search-icon-nav"
          mode="icon"
          label={t('search')}
          icon={'search'}
          invert={darkMode}
          variant="ghost"
        />
      </SearchTrigger>
      <SearchContent>
        <SearchListbox>
          <div
            className="relative text-neutral min-h-[500px]"
            data-test-id={searchViewScenario}
          >
            {hasNoResults && <NoResultsMessage />}
            <div className="flex flex-col lg:flex-row gap-8">
              <div className="lg:flex-[1]">
                <TermListComponent
                  recentlyViewedProducts={recentlyViewedProducts}
                  searchTerm={debouncedSearchTerm}
                  suggestionsResult={suggestionsResult}
                  setIsLoading={setLoading1}
                  setSessionStorageData={setSessionStorageData}
                />
              </div>
              <div className="lg:flex-[2]">
                <ProductListComponent
                  recentlyViewedProducts={recentlyViewedProducts}
                  searchTerm={debouncedSearchTerm}
                  suggestionsResult={suggestionsResult}
                  setIsLoading={setLoading2}
                  setSessionStorageData={setSessionStorageData}
                />
              </div>
            </div>
            {loading && (
              <div className="absolute flex items-center top-0 left-0 w-full h-full min-h-[500px] bg-white">
                <SearchLoadingIndicator />
              </div>
            )}
          </div>
        </SearchListbox>
      </SearchContent>
    </SearchRoot>
  );
};
