import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputCheckbox } from '@cyber-cats/uds/elements';

import { CustomCheckbox } from 'ui/forms/custom/types';

const CustomFormCheckBox = ({
  field,
  required = false,
}: {
  field: CustomCheckbox;
  required?: boolean;
}) => {
  const { register } = useFormContext();

  return (
    <InputCheckbox
      key={field.key}
      name={field.name}
      dataTestId={field.dataTestId}
      id={field.id}
      required={required}
      ref={register}
    >
      {field.label}
    </InputCheckbox>
  );
};
export default React.memo(CustomFormCheckBox);
